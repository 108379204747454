import React from 'react';
import { Link } from 'gatsby';
import { useCart } from 'react-use-cart';
import CartItemList from './CartItemList';
import Sad from '../svg/sad.svg';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';
import Pop from '../sounds/pop.mp3';

function Cart() {
  const { isEmpty, cartTotal } = useCart();

  const historyBack = () => {
    window.history.back();
  };

  const formattedSubTotal = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(cartTotal);

  const [play] = useSound(Switch);
  const [close] = useSound(Pop);

  if (isEmpty)
    return (
      <div className="text-primary">
        <div className="flex justify-center m-16">
          <Sad />
        </div>
        <div className="flex justify-center m-8 leading-loose text-center ">
          <p>
            oh uh, it looks like your bag is empty. <br />
            lets fix that.
          </p>
        </div>
        <section className="flex flex-col w-full md:flex-row">
          <div
            role="button"
            tabIndex={0}
            className="flex flex-col w-full sm:flex-row focus:outline-none"
          >
            <button
              onClick={() => {
                historyBack();
                close();
              }}
              onKeyDown={() => {
                historyBack();
                close();
              }}
              className="secondary-btn"
            >
              continue shopping
            </button>
            <Link
              onClick={play}
              to="/collections"
              className="ml-0 -mt-2 secondary-btn sm:-ml-2 md:mt-0"
            >
              go to collections
            </Link>
          </div>
        </section>
      </div>
    );
  // const freeDeliveryBar = (cartTotal) => {
  //   if (cartTotal >= 80) {
  //     return null;
  //   } else {
  //     return (
  //       <div className="w-full bg-primary">
  //         <p className="py-1 text-sm text-center text-white text-mono">
  //           spend £80 or more for free delivery
  //         </p>
  //       </div>
  //     );
  //   }
  // };

  return (
    <React.Fragment>
      {/* {freeDeliveryBar(cartTotal)} */}
      <CartItemList />
      <div className="">
        <div className="flex flex-col items-end">
          <div
            className="flex flex-row items-end justify-between w-full p-8 border border-primary"
            style={{ marginTop: '-1px' }}
          >
            <span className="font-serif text-2xl font-extrabold text-primary md:text-4xl">
              sub total
            </span>
            <span className="text-xl font-bold lg:text-2xl text-primary">
              {formattedSubTotal}
            </span>
          </div>
          <section className="flex flex-col w-full md:flex-row">
            <div
              onClick={close}
              onKeyDown={close}
              role="button"
              tabIndex={0}
              className="flex flex-col w-full -mt-px sm:flex-row"
            >
              <button onClick={historyBack} className="secondary-btn">
                continue shopping
              </button>
            </div>
            <Link to="/checkout" onClick={play} className="w-full h-full">
              <div className="primary-btn">checkout</div>
            </Link>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Cart;
