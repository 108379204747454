import React from 'react';
import { useCart } from 'react-use-cart';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

function CartItem({ id, name, quantity, price, image, size }) {
  const { updateItemQuantity, removeItem } = useCart();
  const increment = () => updateItemQuantity(id, quantity + 1);
  const decrement = () => updateItemQuantity(id, quantity - 1);
  const remove = () => removeItem(id);

  const total = quantity * price;

  const formattedUnitPrice = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(price);
  const formattedLineTotal = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(total);

  return (
    <div
      key={id}
      className="flex items-center px-6 py-3 border border-primary md:py-6"
      style={{ marginTop: '-1px' }}
    >
      <div className="flex items-center flex-grow w-3/5">
        <div className="w-16 h-16 p-1 mr-4 md:h-20 md:w-20">
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={name}
            title={name}
          />
        </div>
        <div>
          <h4 className="text-sm font-bold text-primary md:text-base">
            <Link to={`/en/products/${name.replace(/\s+/g, '-')}`}>
              {name} - {size}
            </Link>
          </h4>

          <button
            className="flex items-center text-xs italic text-primary hover:text-primary focus:outline-none"
            onClick={remove}
          >
            <svg
              className="w-3 h-3 mr-1 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
            remove
          </button>
        </div>
      </div>

      <div className="flex-col items-center hidden ml-auto md:flex">
        <button
          className="p-1 text-primary hover:text-primary focus:outline-none"
          onClick={increment}
        >
          <svg
            className="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
          </svg>
        </button>

        <span className="p-1 mx-3 md:mx-6 text-primary">{quantity}</span>

        <button
          className="p-1 text-primary hover:text-primary focus:outline-none"
          onClick={decrement}
        >
          <svg
            className="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
      </div>

      <div className="text-right md:w-1/5">
        <p className="font-bold text-primary">{formattedLineTotal}</p>
        {quantity > 1 && (
          <p className="text-xs italic text-primary">
            {formattedUnitPrice} each
          </p>
        )}
      </div>
    </div>
  );
}

export default CartItem;
