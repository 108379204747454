import React from 'react';
import Cart from '../components/Cart';
import Seo from '../components/SEO';

function CartPage() {
  return (
    <React.Fragment>
      <Seo pageTitle="shopping bag" />
      <section className="flex flex-col justify-center h-screen pb-32">
        <div className="px-4 mb-6">
          <h1 className="flex justify-between mb-3 text-3xl font-bold md:text-6xl text-primary">
            shopping bag
          </h1>
        </div>
        <Cart />
      </section>
    </React.Fragment>
  );
}

export default CartPage;
